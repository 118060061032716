@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

}

.donut {
  display: inline-block;
  /* border: 18px solid #F5821F; mockup */
  /* border: 18px solid #f4821c; gif colorpick */
  border: 5px solid #f39749;  
  border-left-color: #E37617;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: donut-spin 0.8s linear infinite;
}


.container {
  margin: 3.75rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  min-height: 350px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001c;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 2rem 3rem;
}
